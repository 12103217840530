import React from "react";
const Error = () => {
  return (
    <div className="section section-center">
      <h2>something went wrong...</h2>
    </div>
  );
};

export default Error;
